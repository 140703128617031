import Vue from 'vue'
import App from './App.vue'
import "./index.css"

Vue.config.productionTip = false
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate, {
  classes: {
    label: 'form-label-styling ml-1',
    input: 'form-input-styling focus:outline-none focus:shadow-outline focus:bg-white focus:border-yellow-500',
    error: 'mt-2 text-red-600 text-xs text-center'
  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')