<template>
  <div class="h-auto py-5 flex justify-center items-center">
    <div class="">
      <div class="text-center">
        <a
          href="https://fostrum.com"
          class="flex justify-center items-baseline"
        >
          <!-- <img class="h-8 mr-2" src="../assets/fostrum-icon.png" /> -->
          <span
            class="
              mt-6
              text-5xl
              font-black
              text-transparent
              bg-center bg-cover bg-gradient-to-br
              from-red-400
              via-indigo-600
              to-green-500
              bg-clip-text
            "
            style="
              background-image: url('https://da2nz7dzzdx58.cloudfront.net/bg-colorful.jpeg');
            "
          >
            fostrum
          </span>
        </a>
        <div class="mt-4 text-3xl text-black font-bold">Get early access</div>
        <p class="max-w-md text-black mt-2 text-center">
          The early access version will give you 🇺🇸
          <span class="font-bold">US</span> & 🇨🇦
          <span class="font-bold">Canadian</span> bank account integrations.
          <span class="text-indigo-400 font-bold">Social media</span> &
          <span class="text-blue-400 font-bold">ecommerce app</span>
          integrations are currently underway 😀
        </p>
        <p class="max-w-md text-md text-black"></p>
      </div>

      <div
        class="
          mt-4
          bg-white
          py-4
          px-4
          md:px-6
          md:py-5
          rounded-3xl
          shadow-md
          mb-10
        "
      >
        <form class="w-full">
          <div class="mb-5">
            <FormulateInput
              v-model="formFields.fullName"
              @validation="nameValidation = $event"
              label="Full Name"
              type="email"
              name="Full Name"
              validation="required"
              placeholder="Samantha Wilson"
            />
          </div>
          <div class="mb-5">
            <div>
              <FormulateInput
                v-model="formFields.email"
                @validation="emailValidation = $event"
                label="Email"
                type="email"
                name="email"
                validation="required|email"
                placeholder="sam@gmail.com"
              />
            </div>
          </div>

          <div class="mb-5">
            <div class="inline-block relative w-full">
              <FormulateInput
                @validation="selectValidation = $event"
                label="Which platform do you primarily use?"
                v-model="formFields.primary_platform"
                :options="{
                  YouTube: 'YouTube',
                  Etsy: 'Etsy',
                  Shopify: 'Shopify',
                  Amazon: 'Amazon',
                  Twitch: 'Twitch',
                  OnlyFans: 'OnlyFans',
                  Patreon: 'Patreon',
                  Podia: 'Podia',
                  Teachable: 'Teachable',
                  Thinkific: 'Thinkific',
                  Other: 'Other',
                }"
                type="select"
                validation="required"
                name="Primary platform"
                placeholder="Select a platform"
              />
            </div>
          </div>
          <div v-if="formFields.primary_platform === 'Other'" class="mb-5">
            <FormulateInput
              v-model="formFields.other"
              label="Other platform"
              type="text"
              name="Other platform"
              placeholder="4th Wall?"
              validation="required"
            />
          </div>
          <div class="mb-5">
            <div>
              <FormulateInput
                v-model="formFields.platform_link"
                @validation="linkValidation = $event"
                label="Link to your profile"
                type="url"
                name="profle link"
                placeholder="https://www.google.com"
                validation="required|url"
              />
            </div>
          </div>
          <div class="mb-5">
            <div class="inline-block relative w-full">
              <FormulateInput
                @validation="accessValidation = $event"
                label="Do you want to start using today?"
                v-model="formFields.access"
                :options="{
                  'Early Access': 'Yes, I want to access ASAP!',
                  WNPIR:
                    'Email me when my platform of choice integration is ready',
                }"
                type="select"
                validation="required"
                name="An option"
                placeholder="Select when you want to get access"
              />
            </div>
          </div>

          <div class="text-center mb-5">
            <span class="text-sm font-bold"
              >We will sign you up for updates
            </span>
          </div>
          <div>
            <div class="text-center">
              <button
                v-if="!isLoading"
                @click="sumbitForm"
                class="
                  shadow
                  bg-yellow-400
                  hover:bg-black
                  focus:shadow-outline
                  focus:outline-none
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded-full
                "
                type="button"
              >
                Get Early Access
              </button>
              <button
                v-else-if="isLoading === 'success'"
                @click="sumbitForm"
                class="
                  shadow
                  bg-green-400
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded-full
                "
                type="button"
                disabled
              >
                Submitted! Thank you :)
              </button>
              <button
                v-else-if="isLoading === 'error'"
                @click="sumbitForm"
                class="
                  shadow
                  bg-red-400
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded-full
                "
                type="button"
                disabled
              >
                Sorry! Something went wrong :(
              </button>
              <button
                v-else-if="isLoading === 'empty'"
                @click="sumbitForm"
                class="
                  shadow
                  bg-gray-400
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded-full
                "
                type="button"
                disabled
              >
                Please fill up all the fields :|
              </button>
              <button
                v-else
                type="button"
                class="
                  bg-indigo-600
                  shadow
                  focus:shadow-outline
                  focus:outline-none
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded-full
                  animate-pulse
                "
                disabled
              >
                Processing
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";

var Airtable = require("airtable");

var airBase = new Airtable({ apiKey: "key7D14CgWbx1ZHIe" }).base(
  "appYylQ8yz8HyaU12"
);
export default {
  name: "HelloWorld",
  data() {
    return {
      nameValidation: {},
      emailValidation: {},
      selectValidation: {},
      linkValidation: {},
      formFields: {
        fullName: "",
        email: "",
        primary_platform: "",
        platform_link: "",
        other: "",
        access: "",
      },
      isLoading: false,
    };
  },
  methods: {
    sumbitForm() {
      // var result = Format();
      const signUpDate = format(new Date(), "yyyy-MM-dd");
      console.log(signUpDate);
      if (
        !this.nameValidation.hasErrors &&
        !this.emailValidation.hasErrors &&
        !this.selectValidation.hasErrors &&
        !this.linkValidation.hasErrors
      ) {
        this.isLoading = true;
        airBase("Waitlist")
          .create([
            {
              fields: {
                "Full Name": this.formFields.fullName,
                Email: this.formFields.email,
                "Primary Platform": this.formFields.primary_platform,
                Link: this.formFields.platform_link,
                Other: this.formFields.other,
                "Sign Up Date": signUpDate,
                Access: this.formFields.access,
              },
            },
          ])
          .then(() => {
            // console.log(res, "Success");
            this.isLoading = "success";
          })
          .catch(() => {
            this.isLoading = "error";

            // console.log(error);
          });
      } else {
        this.isLoading = "empty";

        const vMM = this;
        setTimeout(() => {
          vMM.isLoading = false;
        }, 2000);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
