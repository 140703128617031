<template>
  <div id="app" class="">
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <br />
    <br />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
html {
  height: 100%;
  background-color: #fef7ed;
}
</style>
